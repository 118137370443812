<template>
  <ul class="links" :class="`links--${type}`">
    <li v-for="social in socialLinks" :key="social.name">
      <a
        :href="social.url"
        target="_blank"
        rel="noopener noreferrer"
        :aria-label="social.name"
        class="link"
      >
        <Component
          :is="getIcon(social.name)"
          class="link__icon"
          :class="`link__icon--${social.name}`"
        />
      </a>
    </li>
  </ul>
</template>

<script lang="ts" setup>
import { get } from 'lodash-es'

import { useWebsiteSettings } from '@/state/websiteSettings'

defineProps<{ type: 'blue' | 'brown' }>()

const settings = await useWebsiteSettings()

const getSocialUrl = (social: string): string =>
  get(settings.value, `${social}_url`, '')

const socialLinks = ['instagram', 'facebook', 'youtube']
  .map(social => ({
    name: social,
    url: getSocialUrl(social)
  }))
  .filter(social => social.url)

const getIcon = (icon: string) =>
  defineAsyncComponent(() => import(`../assets/icons/${icon}.svg?component`))
</script>

<style lang="scss" scoped>
.links {
  display: flex;
  gap: rem(9px);

  @include media-up(lg) {
    gap: rem(14px);
  }

  @include media-up(xl) {
    gap: rem(20px);
  }
}

.link {
  @include center-content;
  width: rem(30px);
  aspect-ratio: 1/1;
  color: $blue;
  background: $beige;
  border-radius: 50%;
  box-shadow: 0 3px 3px 0 #00000029;
  transition: transform $transition;

  @include media-up(md) {
    width: rem(34px);
  }

  @include media-up(lg) {
    width: rem(38px);
  }

  @include media-up(xl) {
    width: rem(53px);
  }

  &:hover {
    transform: scale(1.1);
  }

  .contrast-mode & {
    color: $contrast-black;
  }

  .links--brown & {
    color: $beige;
    background: $dark-brown;

    .contrast-mode & {
      color: $contrast-black;
      background-color: $contrast-yellow;
    }
  }

  &__icon {
    width: 50%;
    height: 100%;

    &--facebook {
      width: 23%;
    }
  }
}
</style>
